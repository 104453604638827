import styled from 'styled-components';
import { Box, Button, Container, Typography } from '../UI/elements';
import LocationBg from '../../images/building_images/building_location_background.png';

export const SectionHero = styled(Box)`
  justify-content: center;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const Hero = styled(Box)`
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 398px;
    top: 0;
    position: absolute;
    background: linear-gradient(180deg,rgba(41,45,50,0) 0%,rgba(41,45,50,.3) 30%,rgba(16,17,18,.3) 100%);

  ${({ theme }) => theme.breakpoints.down('md')} {
      height: 100vh;
  }
  }
`;

export const HeroTitleV2 = styled(Typography)`
  font-size: 3.25rem;
  font-weight: 800;
  line-height: 3.875rem;
  margin: 0 1rem;
  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 2rem;
  }
`;

export const HeroTitle = styled(Typography)`
  font-size: 3.25rem;
  font-weight: 300;
  font-family: 'LexendExa';
  line-height: 3.875rem;
  margin: 1.5rem 0rem;
  color: ${({ theme }) => theme.palette.secondary.main};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

export const ApartmentsSection = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.secondary.light};
`;

export const ApartmentsSectionTextWrapper = styled(Box)`
  width: 100%;
  text-align: center;
`;

export const Section = styled(Box)`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 2.5rem;
`;

export const SectionTitle = styled(Typography).attrs({
  fontFamily: 'LexendExa',
  fontSize: 40,
  fontWeight: 300,
  textAlign: 'center',
  mb: 0.5
})`
  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 40px;
    margin-bottom: 1.5rem;
    padding: 0 1.5rem;
  }
`;

export const SectionSubtitle = styled(Typography).attrs({
  fontWeight: 400,
  lineHeight: 1.68
})`
  margin-bottom: 2rem;
  font-size: 18px;
  font-family: 'Inter';
  color: #374659;
`;

export const SectionMiniTitle = styled(Typography)`
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 400;
  color: #374659;
`;

export const WhatsappButton = styled(Button).attrs({
  variant: 'contained',
  size: 'large',
  px: '1.5rem',
  py: '1rem'
})`
  color: white;
  background: #25CC62;
  font-family: 'Inter';
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    background: #0F933F;
    color: white;
  }
  &:focus {
    outline: 2px solid #25CC62;
  }
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const WhatsappButtonDark = styled(Button).attrs({
  variant: 'contained',
  size: 'large',
  px: '1.5rem',
  py: '0.68rem'
})`
  color: white;
  background: #343434;
  font-family: 'Inter';
  font-weight: 400;
  text-transform: uppercase;

  &:hover {
    background: #4a4949;
    color: white;
  }
  &:focus {
    outline: 2px solid #4a4949;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const HeroTextWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  text-align: center;
  bottom: 0;
  padding-bottom: 3rem;
  font-family: 'LexendExa';

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: ${({ ctaShown }) => ctaShown ? '6rem' : '2rem'};
  }
`;

export const ExperiencePictureLeft = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 629px;
  height: 365px;
  border-radius: 80px 8px;
  object-fit: cover;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 186px;
    margin-bottom: 1rem;
  }
`;

export const ExperiencePictureRight = styled(Box)`
  min-width: 343px;
  width: 100%;
  max-width: 629px;
  position: absolute;
  object-fit: cover;
  z-index: -1;
  right: 0;
  top: 59px;
  height: 365px;
  border-radius: 80px 8px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 186px;
    position: static;
  }
`;

export const ImagesSection = styled(Box)`
  position: relative;
  width: 100%;
  margin-bottom: 5rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const ThirdSection = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  white-space: break-spaces;
  padding: 7rem 2rem;
  background-image: url(${LocationBg});
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: lighten;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 3rem 0.5rem;
    background-image: none;
  }
`;

export const ThirdSectionContainer = styled(Container)`
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    align-items: center;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding-left: 1rem;
      padding-right: 1rem;
      flex-direction: column;
  }
`;

export const ThirdSectionPolaroid = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  width: 262px;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('md')}{
    position: relative;
    margin-bottom: 1.5rem;
    padding: 1rem;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export const ThirdSectionPolaroidPicture = styled(Box)`
  width: 231px;
  height: 270px;


  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
